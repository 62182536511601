// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Welcome from './Welcome';
import MainPage from './MainPage'; 
import Callback from './Callback';

import './App.css';


function App() {
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const hash = window.location.hash
      .substring(1)
      .split('&')
      .reduce((acc, item) => {
        const parts = item.split('=');
        acc[parts[0]] = decodeURIComponent(parts[1]);
        return acc;
      }, {});

    if (hash.access_token) {
      setAccessToken(hash.access_token);
      window.location.hash = '';
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          {!accessToken && <Route path="/" element={<MainPage />} />}
          {accessToken && <Route path="/" element={<Welcome />} />}
          <Route path="/callback" element={<Callback />} />
        </Routes>
        <footer className="footer">
          &copy; 2023 Cutime. All Rights Reserved.
        </footer>
      </div>
    </Router>
  );
}

export default App;
