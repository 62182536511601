// SearchBar.js
import React, { useState, useEffect } from 'react';
import SearchResult from './SearchResult';
import './SearchBar.css';

function SearchBar({ onSongSelect }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const search = async () => {
      const search_uri = process.env.REACT_APP_BACKEND + `/api/search?q=${encodeURIComponent(searchQuery)}`;
      console.log('Using URI', search_uri);
      const response = await fetch(search_uri);
      const results = await response.json();
      setSearchResults(results);
    };
  
    const timeoutId = setTimeout(() => {
      if (searchQuery) {
        search();
      } else {
        setSearchResults([]);
      }
    }, 300);
  
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);
  

  return (
    <div className="SearchBar">
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search for a song..."
      />
      {searchResults.length > 0 && (
        <div className="SearchResults">
          {searchResults.map((result) => (
            <SearchResult key={result.id} result={result} onSongSelect={onSongSelect} /> 
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchBar;
