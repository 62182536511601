import React, { useState, useRef, useEffect } from 'react';
import { Card, CardContent, CardMedia, Typography, ButtonBase, Box, IconButton, Backdrop } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import LikeButton from './LikeButton';

function RecommendationCard({ song, reason, onLike }) {
  const [isLiked, setIsLiked] = useState(false);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio(song.preview_url));

  const handleClick = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/track_click`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ song_id: song.id })
    });

    if (!response.ok) {
      console.error("Failed to track click");
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production' && showPlayButton) {
      console.log(song);
    }
  }, [showPlayButton, song]);

  const handlePlayPause = () => {
    if (song.preview_url) {
      if (audioRef.current.paused) {
        audioRef.current.play();
        setIsPlaying(true);
      } else {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleLike = () => {
    setIsLiked(!isLiked);
    if (onLike) {
      onLike(song, !isLiked);
    }
  };

  const handleImageLoad = (event) => {
    if (process.env.NODE_ENV !== 'production') {
      const width = event.target.naturalWidth;
      const height = event.target.naturalHeight;
      console.log(`Image dimensions: ${width}x${height}`);
    }
  };

  return (
    <Card elevation={3} sx={{ width: 280, marginBottom: 2, padding: 1, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ position: 'relative', height: 250, width: '100%' }} onMouseEnter={() => setShowPlayButton(true)} onMouseLeave={() => setShowPlayButton(false)}>
        <ButtonBase
          component="a"
          href={song.external_urls.spotify}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
          sx={{ height: '100%', width: '100%' }}
        >
          <CardMedia
            component="img"
            height="100%"
            width="100%"
            image={song.album.images[1].url}
            sx={{ maxWidth: 250, maxHeight: 250, position: 'relative' }}
            alt={song.name}
            onLoad={handleImageLoad}
          />
          {showPlayButton && song.preview_url && (
            <>
              <Backdrop open={showPlayButton} sx={{ 
                  position: 'absolute', 
                  top: 0, left: 0, 
                  width: '100%', height: '100%', 
                  backgroundColor: 'rgba(255, 255, 255, 0.6)',
                  zIndex: 1
              }}/>
              <IconButton onClick={(e) => { e.preventDefault(); handlePlayPause(); }} sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
                {isPlaying ? <PauseCircleOutlineIcon fontSize="large" color="action" /> : <PlayCircleOutlineIcon fontSize="large" color="action" />}
              </IconButton>
            </>
          )}
        </ButtonBase>
      </Box>
      <CardContent sx={{ textAlign: 'center', flex: '1' }}>
        <Typography variant="h6" component="div">
          {song.name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {song.artists.map((artist) => artist.name).join(', ')}
        </Typography>
        <Typography variant="body1" component="p" sx={{ marginTop: 0.5, color: '#666' }}>
          {reason}
        </Typography>
      </CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto' }}>
        <LikeButton onClick={handleLike} isLiked={isLiked} />
      </Box>
    </Card>
  );
}

export default RecommendationCard;
