import React from 'react';

const Welcome = () => {
  return (
    <div className="Welcome">
      <h1>Welcome to Cutime</h1>
    </div>
  );
};

export default Welcome;
