import React, { useState, useEffect, useCallback } from 'react';
import './RecommendationPage.css';
import Navbar from './Navbar';
import RecommendationCard from './components/RecommendationCard';
import PlaceholderCard from './PlaceholderCard';
import SpotifyWebApi from 'spotify-web-api-js';
import SpotifyAuth from './SpotifyAuth';
import SpotifyLogoGreen from './Spotify_Logo_RGB_Green.png';
import { v4 as uuidv4 } from 'uuid';
import FavoriteTraitSelection from './FavoriteTraitSelection';

// const generateRandomString = (length) => {
//   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//   let result = '';
//   for (let i = 0; i < length; i++) {
//     result += characters.charAt(Math.floor(Math.random() * characters.length));
//   }
//   return result;
// };

function RecommendationPage({ song }) {
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [likedSongs, setLikedSongs] = useState([]);
  // const [authState, setAuthState] = useState(null);
  const authState = useState(null)[0];
  const sessionID = useState(uuidv4())[0];
  const [favoriteTraitOptionsLoading, setFavoriteTraitOptionsLoading] = useState(true);

  const maxRecommendations = 50; // Set the maximum number of recommendations allowed

  const [favoriteTraitOptions, setFavoriteTraitOptions] = useState([]);

  useEffect(() => {
    const fetchFavoriteTraits = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/favoritetrait`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            song_id: song.id,
          }),
          credentials: 'include',
        });
        const fetchedFavoriteTraitOptions = await response.json();
        setFavoriteTraitOptions(fetchedFavoriteTraitOptions);
      } catch (error) {
        console.error("Error fetching reasons:", error);
        setError(error.message);
      } finally {
        // If the fetched reasons are not empty, then we are done loading
        setFavoriteTraitOptionsLoading(false); 
      }    
    };
    
    if (song.id) {
      fetchFavoriteTraits();
    }
  }, [song.id]);

  const handleFavoriteTraitSelectionClick = async (favoriteTrait) => {
    console.log('Clicked Favorite Trait:', favoriteTrait);
    setLoading(true);
    setRecommendations([]);
    try {
      console.log("fetching recommendations with session ID " + sessionID + ", song ID " + song.id + " and favorite trait " + favoriteTrait + "...")
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/recommendations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          song_id: song.id,
          liked_songs: [],
          session_id: sessionID,
          favorite_trait: favoriteTrait['description'], 
        }),
        credentials: 'include',
      });
      const results = await response.json();
      setRecommendations(results);
    } catch (error) {
      console.error("Error fetching recommendations:", error);
      setError(error.message); 
    } finally {
      setLoading(false);
    }
  };  

  useEffect(() => {
    const fetchRecommendations = async () => {
      setLoading(true);
      try {
        console.log("fetching recommendations with session ID " + sessionID + " and song ID " + song.id + "...")
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/recommendations`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            song_id: song.id,
            liked_songs: [],
            session_id: sessionID,
          }),
          credentials: 'include',
        });
        const results = await response.json();
        setRecommendations(results);
      } catch (error) {
        console.error("Error fetching recommendations:", error);
        setError(error.message); 
      } finally {
        setLoading(false);
      }
    };    
    fetchRecommendations();
  }, [song.id, sessionID]);

  const handleLike = useCallback((song, isLiked) => {
    if (isLiked) {
      const newLikedSongs = [...likedSongs, song];
      setLikedSongs(newLikedSongs);
      localStorage.setItem('likedSongs', JSON.stringify(newLikedSongs));
    } else {
      const newLikedSongs = likedSongs.filter((likedSong) => likedSong.id !== song.id);
      setLikedSongs(newLikedSongs);
      localStorage.setItem('likedSongs', JSON.stringify(newLikedSongs));
    }
  }, [likedSongs]);

  useEffect(() => {
    // Log liked songs everytime it changes
    if (process.env.NODE_ENV !== 'production') {
      console.log("Liked songs:", likedSongs);
    }
  }, [likedSongs]);
  

  // const handleCreatePlaylist = useCallback(() => {
  //   if (!likedSongs.length) {
  //     alert('No songs have been liked. Please like some songs before creating a playlist.');
  //     return;
  //   }
  //   setAuthState(generateRandomString(16));
  // }, [likedSongs]);

  const handleLoadMore = async () => {
    if (recommendations.length >= maxRecommendations) {
      alert('Maximum number of recommendations reached.');
      return;
    }

    setLoading(true);
    // generate likedSongsIds array
    const likedSongIds = likedSongs.map((song) => song.id);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/recommendations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          song_id: song.id,
          liked_song_ids: likedSongIds,
          session_id: sessionID,
        }),
        credentials: 'include',
      });
      const newRecs = await response.json();
      setRecommendations((prevRecs) => [...prevRecs, ...newRecs]);
    } catch (error) {
      console.error("Error fetching recommendations:", error);
    }
    setLoading(false);
  };

  const createPlaylistFromLikedSongs = async (accessToken, likedSongs) => {
    console.log(likedSongs);
    if (!likedSongs) {
      alert('No songs have been liked. Please like some songs before creating a playlist.');
      return;
    }
  
    const spotifyApi = new SpotifyWebApi();
    spotifyApi.setAccessToken(accessToken);
  
    try {
      const userData = await spotifyApi.getMe();
      const playlist = await spotifyApi.createPlaylist(userData.id, {
        name: 'Liked Songs from Cutime',
        public: false,
      });

      const likedSongIds = likedSongs.map((song) => song.id);
  
      const trackUris = likedSongIds.map((songId) => `spotify:track:${songId}`);
      await spotifyApi.addTracksToPlaylist(playlist.id, trackUris);
  
      alert('Playlist created successfully!');
      window.location.href = playlist.external_urls.spotify; // Redirect the user to the playlist on Spotify
    } catch (err) {
      console.error('Failed to create playlist:', err);
      alert('Failed to create playlist. Please ensure you are logged in with Spotify.');
}
  };

  const placeholderRecommendations = new Array(4).fill(null);

  return (
    <>
      <Navbar>
        <div className="logo-container">
          <a href="/"><h3 className="logo">Cutime</h3></a>
        </div>
        <div className="back-to-search-container">
          <a href="/" className="back-to-search">Back to Search</a>
        </div>
        <div className="spotify-container">
          <span className="spotify-text">music from</span>
          <img src={SpotifyLogoGreen} alt="Spotify Logo" className="spotify-logo" />
        </div>
      </Navbar>
      <div className="RecommendationPage">
        <h2>Recommendations based on </h2>
        <h1> <i>{song.name}</i> by {song.artists[0].name}</h1>
        {
          !favoriteTraitOptionsLoading && favoriteTraitOptions.length > 0 && (
            <FavoriteTraitSelection reasons={favoriteTraitOptions} onReasonButtonClick={handleFavoriteTraitSelectionClick} reasonsLoading={favoriteTraitOptionsLoading} />
          )
        }
        {error && <p className="error-message">{error}</p>}
        <div className="recommendations">
          {recommendations.map((recommendation, index) => {
            if (recommendation.song) {
              return (
                <RecommendationCard
                  key={recommendation.song.id}
                  song={recommendation.song}
                  reason={recommendation.reason}
                  delay={index * 100}
                  onLike={handleLike}
                />
              );
            }
            return null;
          })}
          {loading &&
            placeholderRecommendations.map((_, index) => <PlaceholderCard key={index} />)}
        </div>
        <div className="load-more-container">
          <button
            className="load-more-btn"
            onClick={handleLoadMore}
            disabled={recommendations.length >= maxRecommendations}
          >
            Load More Based on Liked Songs
          </button>
        </div>
        <div className="action-buttons">
          <button className="create-playlist-btn" >
            {/* onClick={handleCreatePlaylist} */}
            Create Playlist (Coming Soon)
          </button>
        </div>
        <SpotifyAuth
          onAccessToken={(accessToken) => createPlaylistFromLikedSongs(accessToken, likedSongs)}
          state={authState}
        />
      </div>
    </>
  );
}

export default RecommendationPage;
