import React from 'react';
import './PlaceholderCard.css';

function PlaceholderCard() {
  return (
    <div className="PlaceholderCard">
      <div className="PlaceholderCard-image"></div>
      <div className="PlaceholderCard-info">
        <div className="PlaceholderCard-title"></div>
        <div className="PlaceholderCard-artist"></div>
        <div className="PlaceholderCard-reason">
          <div className="PlaceholderCard-reason-text">Loading recommendations...</div>
        </div>
      </div>
    </div>
  );
}

export default PlaceholderCard;
