import { useEffect } from 'react';
import SpotifyWebApi from 'spotify-web-api-js';

const Callback = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.hash.substr(1));
    const accessToken = urlParams.get('access_token');
    const state = urlParams.get('state');

    if (state && accessToken) {
      const likedSongsJSON = localStorage.getItem('likedSongs');
      const likedSongs = JSON.parse(likedSongsJSON);
      createPlaylistFromLikedSongs(accessToken, likedSongs);
    }
  }, []);

  const createPlaylistFromLikedSongs = async (accessToken, likedSongs) => {
    if (!likedSongs || !likedSongs.length) {
      alert('No songs have been liked. Please like some songs before creating a playlist.');
      return;
    }

    const spotifyApi = new SpotifyWebApi();
    spotifyApi.setAccessToken(accessToken);

    try {
      const userData = await spotifyApi.getMe();
      const playlist = await spotifyApi.createPlaylist(userData.id, {
        name: 'Liked Songs from Cutime',
        public: false,
      });

      const likedSongIds = likedSongs.map((song) => song.id);

      const trackUris = likedSongIds.map((songId) => `spotify:track:${songId}`);
      await spotifyApi.addTracksToPlaylist(playlist.id, trackUris);

      window.location.href = playlist.external_urls.spotify; // Redirect the user to the playlist on Spotify
    } catch (err) {
      console.error('Failed to create playlist:', err);
      alert('Failed to create playlist. Please ensure you are logged in with Spotify.');
    }
  };

  return (
    <div>
      {/* ... Your callback page content */}
    </div>
  );
};

export default Callback;
