import { useEffect } from 'react';

const clientId = '56b865d4845d48f0aa9abcdbffa9f37b';
const redirectUri = window.location.origin + '/callback';
const scopes = 'user-read-private playlist-modify-public playlist-modify-private';

const authEndpoint = 'https://accounts.spotify.com/authorize';

const SpotifyAuth = ({ state }) => {
  const authUrl = `${authEndpoint}?client_id=${clientId}&response_type=token&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scopes)}&state=${encodeURIComponent(state)}`;

  useEffect(() => {
    const handleClick = () => {
      const authUrlWithState = `${authUrl}&state=${encodeURIComponent(state)}`;
      window.location = authUrlWithState;
    };

    if (state) {
      handleClick();
    }
  }, [state, authUrl]);

  return null;
};

export default SpotifyAuth;
