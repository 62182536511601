// MainPage.js
import React, { useState } from 'react';
import SearchBar from './SearchBar';
import RecommendationPage from './RecommendationPage';
import Navbar from './Navbar';

function MainPage() {
  const [selectedSong, setSelectedSong] = useState(null);

  const handleSongSelect = (song) => {
    setSelectedSong(song);
    // Close the searchbar menu

  }

  console.log('MainPage render', selectedSong);

  return (
    <div className="MainPage">
      {!selectedSong && (
        <div className="hero-section">
          <Navbar>
                    <a href="/">
                      <h3 className="logo">Cutime</h3>{' '}
                    </a>
          </Navbar>
          <h1>Curate Your Perfect Playlist</h1>
          <h2>recommendations with reasons, starting with a single track you love ❤️</h2>
          <SearchBar onSongSelect={handleSongSelect} />
        </div>
      )}

      {selectedSong && <RecommendationPage song={selectedSong} />}
    </div>
  );
}

export default MainPage;
