// SearchResult.js
import React from 'react';
import './SearchResult.css';

function SearchResult({ result, onSongSelect }) {
  const handleClick = () => {
    onSongSelect(result); // Pass the entire result object instead of just result.id
  };

  return (
    <div className="SearchResult" onClick={handleClick}>
      <img src={result.album.images[2].url} alt={result.name} />
      <div className="SearchResult-info">
        <h4>{result.name}</h4>
        <p>{result.artists.map((artist) => artist.name).join(', ')}</p>
      </div>
    </div>
  );
}

export default SearchResult;
