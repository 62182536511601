import React from 'react';
import Button from '@mui/joy/Button';
import ThumbUp from '@mui/icons-material/ThumbUp';

function LikeButton({ onClick, isLiked }) {
  const handleClick = (e) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <Button
      color="success"
      disabled={false}
      onClick={handleClick} // make sure to use your handleClick here
      size="md"
      variant={isLiked ? "solid" : "soft"} // change the variant based on isLiked
    >
      <ThumbUp />
    </Button>
  );
}

export default LikeButton;