import React, { useState } from 'react';
import './FavoriteTraitSelection.css';

const FavoriteTraitSelection = ({ reasons, onReasonButtonClick, reasonsLoading }) => {
    const [selectedTrait, setSelectedTrait] = useState(null);

    const handleClick = (reason) => {
      setSelectedTrait(reason.id);
      onReasonButtonClick(reason);
    };

    return (
      <div className="favorite-trait-selection">
        {!reasonsLoading && (
          <>
            <h3>What I like the most about this song:</h3>
            <div className="reasons-container">
              {reasons.map((reason) => (
                <button
                  key={reason.id}
                  className={selectedTrait === reason.id ? 'reason-button selected' : 'reason-button'}
                  onClick={() => handleClick(reason)}
                >
                  {reason.description}
                </button>
              ))}
            </div>
          </>
        )}
      </div>
    );
};

export default FavoriteTraitSelection;
